<script>
import {mapActions} from "vuex";
import axios from "axios";
import API from "@/utils/api";
import useVuelidate from "@vuelidate/core";
import {alpha, email, numeric, required, sameAs} from "@vuelidate/validators";

export default {
  name: "SignUpForm",
  data(){
    return {
      type: "individual",
      individual: {
        last_name: '',
        first_name: '',
        middle_name: '',
        phone: '',
        email: '',
        password: '',
        password_repeat: ''
      },
      legal: {
        organization: '',
        inn: '',
        legal_address: '',
        phone: '',
        email: '',
        product_card: '',
        contact_person: {
          last_name: '',
          first_name: '',
          email: ''
        },
        password: '',
        password_repeat: ''
      }
    }
  },
  setup: () => ({ v$: useVuelidate() }),
  validations (){
    return {
      individual: {
        last_name: {required, alpha},
        first_name: {required, alpha},
        middle_name: {alpha},
        phone: {required, numeric},
        email: {required, email},
        password: {required},
        password_repeat: sameAs(this.individual.password),
      }
    }
  },
  computed: {
    showSignUp: {
      get(){
        return this.$store.state.app.showSignUpForm
      },
      set(value){
        this.setShowSignUpForm(value)
      }
    }
  },
  methods:{
    ...mapActions('app', [
        'setShowSignUpForm'
    ]),
    signUp(){
      let user = {}
      if(this.type === 'individual'){
        user = this.individual
      }else{
        user = this.legal
      }
      let baseUrl = process.env.VUE_APP_API_BASE_URL
      axios.post(baseUrl + '/1.0/user/sign-up', {type: this.type, data: user})
          .then(response => {
              console.log(response.data)
          })
    }
  }
}
</script>

<template>
  <Modal wrapperClass="modal-sign-up" v-model="showSignUp" title="Регистрация" :modal-class="'w-3/5'">
    <div class="flex items-center justify-between">
      <label class="inline-flex items-center">
        <input v-model="type" class="form-radio" type="radio" checked="" name="type" value="individual">
        <span class="ml-2">Физ.лицо(частное лицо)</span>
      </label>
      <label class="inline-flex items-center">
        <input v-model="type" class="form-radio" type="radio" name="type" value="legal_entity">
        <span class="ml-2">Юр.лицо(организация/ИП)</span>
      </label>
    </div>
    <div v-if="type === 'individual'">
      <label class="block">
        <span class="text-sm text-gray-500">Фамилия</span>
        <input v-model="individual.last_name" class="mt-1 block w-full h-9 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" type="text">
      </label>
      <div class="flex">
        <label class="w-full block mr-2">
          <span class="text-sm text-gray-500">Имя</span>
          <input v-model="individual.first_name" class="w-full mt-1 block h-9 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" type="text">
        </label>
        <label class="w-full block ml-2">
          <span class="text-sm text-gray-500">Отчество</span>
          <input v-model="individual.middle_name" class="w-full mt-1 block h-9 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" type="text">
        </label>
      </div>
      <div class="flex">
        <label class="w-full block mr-2">
          <span class="text-sm text-gray-500">Телефон</span>
          <input v-model="individual.phone" class="w-full mt-1 block h-9 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" type="text">
        </label>
        <label class="w-full block ml-2">
          <span class="text-sm text-gray-500">E-mail</span>
          <input v-model="individual.email" class="w-full mt-1 block h-9 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" type="text">
        </label>
      </div>
      <div class="flex">
        <label class="w-full block mr-2">
          <span class="text-sm text-gray-500">Придумайте пароль</span>
          <input v-model="individual.password" class="w-full mt-1 block h-9 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" type="text">
        </label>
        <label class="w-full block ml-2">
          <span class="text-sm text-gray-500">Повторите пароль</span>
          <input v-model="individual.password_repeat" class="w-full mt-1 block h-9 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" type="text">
        </label>
      </div>
    </div>
    <div v-else-if="type === 'legal_entity'">
      <div class="flex">
        <label class="w-full block mr-2">
          <span class="text-sm text-gray-500">Наименование организации (ИП)</span>
          <input v-model="legal.organization" class="w-full mt-1 block h-9 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" type="text">
        </label>
        <label class="w-full block ml-2">
          <span class="text-sm text-gray-500">ИНН</span>
          <input v-model="legal.inn" class="w-full mt-1 block h-9 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" type="text">
        </label>
      </div>
      <label class="block">
        <span class="text-sm text-gray-500">Юридический адрес</span>
        <input v-model="legal.legal_address" class="mt-1 block w-full h-9 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" type="text">
      </label>
      <div class="flex">
        <label class="w-full block mr-2">
          <span class="text-sm text-gray-500">Телефон для связи</span>
          <input v-model="legal.phone" class="w-full mt-1 block h-9 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" type="text">
        </label>
        <label class="w-full block ml-2">
          <span class="text-sm text-gray-500">E-mail</span>
          <input v-model="legal.email" class="w-full mt-1 block h-9 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" type="text">
        </label>
      </div>
      <fieldset class="contact-person">
        <legend>
          Контактное лицо для связи
        </legend>
        <div>
          <label class="block">
            <span class="text-sm text-gray-500">Фамилия</span>
            <input v-model="legal.contact_person.last_name" class="mt-1 block w-full h-9 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" type="text">
          </label>
          <div class="flex">
            <label class="mr-2 w-full block">
              <span class="text-sm text-gray-500">Имя</span>
              <input v-model="legal.contact_person.first_name" class="mt-1 w-full block h-9 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" type="text">
            </label>
            <label class="ml-2 w-full block">
              <span class="text-sm text-gray-500">E-mail</span>
              <input v-model="legal.contact_person.email" class="mt-1 w-full block h-9 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" type="text">
            </label>
          </div>
        </div>
      </fieldset>
      <div class="flex">
        <label class="w-full block mr-2">
          <span class="text-sm text-gray-500">Придумайте пароль</span>
          <input v-model="legal.password" class="w-full mt-1 block h-9 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" type="text">
        </label>
        <label class="w-full block ml-2">
          <span class="text-sm text-gray-500">Повторите пароль</span>
          <input v-model="legal.password_repeat" class="w-full mt-1 block h-9 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" type="text">
        </label>
      </div>
    </div>
    <div class="flex">
      <div class="flex-1 pt-2 pr-2">
        <h4 class="text-sm">Что дает регистрация?</h4>
        <ul class="text-xs">
          <li>- Создание личного кабинета</li>
          <li>- Накопление бонусных баллов и оплата ими покупок</li>
          <li>- Быстрое оформление заказа автозаполнением полей</li>
          <li>- Отслеживание истории покупок</li>
          <li>- Быть в курсе всех новостей и разработок компании</li>
        </ul>
      </div>
      <div class="flex-1 flex flex-col justify-between pt-2 pl-2">
        <label class="inline-flex items-center">
          <input type="checkbox" class="rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-offset-0 focus:ring-indigo-200 focus:ring-opacity-50" checked="">
          <span class="ml-2 text-xs">Согласие на обработку персональных данных политика конфиденциальности</span>
        </label>
        <label class="inline-flex items-center">
          <input type="checkbox" class="rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-offset-0 focus:ring-indigo-200 focus:ring-opacity-50" checked="">
          <span class="ml-2 text-xs">Подписаться на новости</span>
        </label>
      </div>
    </div>
    <div class="bottom-line"></div>
    <div class="flex justify-end">
      <button @click="signUp" class="sign-up" type="button">Зарегистрироваться</button>
    </div>
  </Modal>
</template>
<style scoped lang="scss">
  .contact-person{
    border: 1px solid threedface;
    padding: 10px;
    margin-bottom: 10px;

    legend{
      display: block;
      width: auto;
      padding: 0 5px 0 10px;
      line-height: inherit;
      border-bottom: none;
      margin: 0 auto;
      position: relative;
      text-align: center;
      font-size: 14px;
      color: #929292;
    }
  }
  .bottom-line{
    border-top: 1px solid #D4D4D4;
    height: 1px;
    width: 100%;
    margin: 10px 0;
  }
  .sign-up{
    border-radius: 5px;
    margin-top: 10px;
    background-color: #EC842F;
    color: #fff;
    border: 1px solid transparent;
    padding: 6px 12px;

    &:hover{
      color: #333;
      text-decoration: none;
    }
  }
</style>