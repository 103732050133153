<script>
import {mapActions, mapState} from "vuex";
import axios from "axios";
import API from "@/utils/api";

export default {
  name: "ContactPanel",
  props: {
    title: String,
    data: String
  },
  data(){
    return {

    }
  },
  methods:{

  }
}
</script>

<template>
  <div class="w-1/4 rounded-md">
    <h3 class="title-contact rounded-t-md">{{title}}</h3>
    <div class="p-4 bg-white rounded-b-md" v-html="data"></div>
  </div>
</template>

<style scoped lang="scss">
.title-contact{
  background: url("@/assets/header_bg2.jpg");
  text-transform: uppercase;
  color: #fff;
  font-size: 20px;
  text-align: center;
  margin-bottom: 0;
}
</style>