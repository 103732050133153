<template>
  <main-header></main-header>
  <router-view :key="$route.fullPath"/>
  <auth-form></auth-form>
  <sign-up-form></sign-up-form>
  <main-footer></main-footer>
</template>

<style lang="scss">
body{
  background-color: #F7F3EC;
  min-height: 100vh;
}

.modal {
  min-width: 300px;
}
@media (min-width: 480px) {
  .modal.modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal.modal-lg,
  .modal.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal.modal-xl {
    max-width: 1140px;
  }
}
</style>
<script>
import MainHeader from "@/components/MainHeader";
import ProductCarousel from "@/components/ProductCarousel";
import axios from "axios";
import {mapActions} from "vuex";
import AuthForm from "@/components/AuthForm.vue";
import SignUpForm from "@/components/SignUpForm.vue";
import MainFooter from "@/components/MainFooter.vue";

export default {
  components: {
    MainFooter,
    SignUpForm,
    AuthForm,
    MainHeader,
    ProductCarousel
  },
  methods: {
    ...mapActions('app', [
      'setMenu',
      'setBanners',
      'setStorages'
    ]),
    async getMenu(){
      try {
        let baseUrl = process.env.VUE_APP_API_BASE_URL
        const response = await axios.get(baseUrl + '/1.0/catalog/menu')
        await this.setMenu(response.data)
      }catch (error){
        throw new Error(`Unable to get menu`);
      }
    },
    async getBanners(){
      try {
        let baseUrl = process.env.VUE_APP_API_BASE_URL
        const response = await axios.get(baseUrl + '/1.0/banners')
        await this.setBanners(response.data)
      }catch (error){
        throw new Error(`Unable to get banners`);
      }
    },
    async getStorages(){
      try {
        let baseUrl = process.env.VUE_APP_API_BASE_URL
        const response = await axios.get(baseUrl + '/1.0/storages')
        await this.setStorages(response.data)
      }catch (error){
        throw new Error(`Unable to get storages`);
      }
    }
  },
  created() {
    this.getMenu()
    this.getBanners()
    this.getStorages()
  }
}
</script>