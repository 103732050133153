<template>
  <div class="p-4">
    <div class="title-content flex items-center">
      <h2 class="storage-title">Контакты</h2>
      <hr>
    </div>
    <div class="flex justify-between">
      <contact-panel title="розничная торговля" :data="data"></contact-panel>
      <contact-panel title="оптовая торговля" :data="data"></contact-panel>
      <contact-panel title="партнерство торговля" :data="data"></contact-panel>
    </div>
    <div class="title-content flex items-center">
      <h2 class="storage-title">Региональный склад</h2>
      <hr>
    </div>
    <div class="storage-row flex bg-white p-4 justify-between" v-for="storage in storages">
      <div class="w-1/4" v-html="storage.address + storage.requisites"></div>
      <div class="w-2/5 h-[300px] px-4">
        <swiper>
          <swiper-slide v-for="picture in storage.pictures">
            <img class="max-h-[300px] w-full object-cover" :src="picture.url"/>
          </swiper-slide>
        </swiper>
      </div>
      <div class="w-1/4 flex justify-end" v-html="storage.map"></div>
    </div>
    <div class="title-content flex items-center">
      <h2 class="storage-title">Наши партнеры</h2>
      <hr>
    </div>
    <div>
      <table class="partner-table w-full max-w-full mr-1 mb-4">
        <thead>
        <tr>
          <th>№</th>
          <th>Город</th>
          <th>Логотип</th>
          <th>Наименование</th>
          <th>Адрес</th>
          <th>Телефон</th>
          <th>Ссылка</th>
        </tr>
        </thead>
        <tbody></tbody>
      </table>
    </div>
  </div>
</template>
<script>
import ProductCardGrid from "@/components/ProductCardGrid";
import axios from "axios";
import {Swiper, SwiperSlide} from "swiper/vue";
import ContactPanel from "@/components/ContactPanel.vue";

export default {
  name: 'ContactsView',
  components: {
    ContactPanel,
    SwiperSlide, Swiper,
    ProductCardGrid
  },
  data() {
    return {
      storages: [],
      data: "<p>тел / факс: </p><p>моб: </p><p>e-mail: </p>"
    }
  },
  methods: {
    async getStorages() {
      try {
        let baseUrl = process.env.VUE_APP_API_BASE_URL
        const response = await axios.get(baseUrl + '/1.0/storages')
        this.storages = response.data
      } catch (error) {
        throw new Error(`Unable to get storages`);
      }
    }
  },
  created() {
    this.getStorages()
  }
}
</script>
<style scoped lang="scss">
.storage-row {
  border: 1px solid #9D9A95;
}

.storage-title {
  color: #646263;
  font-size: 26px;
  font-weight: 400;
  margin: 0 10px 5px 0;
  padding-top: 10px;
  text-transform: uppercase;
}

.title-content {
  hr {
    height: 2px;
    background-color: #CAC5C1;
    flex-grow: 1;
    margin-bottom: 10px;
    border-top: 1px solid #eeeeee;
    margin-top: 20px;
  }
}
.partner-table{
  border: 1px solid #ddd;
  border-collapse: collapse;
  border-spacing: 0;

  thead{
    background-color: #E1E1E1;
    font-size: 16px;

    th{
      border: 1px solid #a2a2a2;
      padding: 8px 0 8px 0;
    }
  }

  tbody{
    background-color: #fff;
  }
}
</style>
