<template>
  <banner-carousel></banner-carousel>
  <product-carousel v-if="newProducts.length !== 0" :products="newProducts" title='новинки/лидеры продаж' :class="'new-swiper'"></product-carousel>
  <product-carousel v-if="discountProducts.length !== 0" :products="discountProducts" title='Товары со скидкой' :class="'discount-swiper'"></product-carousel>
</template>

<script>
import BannerCarousel from "@/components/BannerCarousel";
import ProductCarousel from "@/components/ProductCarousel";
import {mapGetters} from "vuex";

export default {
  name: 'HomeView',
  components: {
    BannerCarousel,
    ProductCarousel
  },
  data(){
    return {
    }
  },
  computed: {
    ...mapGetters('app', [
        'newProducts',
        'discountProducts'
    ])
  },
  methods:{

  },
  mounted() {
    
  }
}
</script>
