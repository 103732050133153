<script>
import axios, { AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import API from "@/utils/api";
export default {
  name: "UserView",
  methods: {
     loadData() {
      let baseUrl = process.env.VUE_APP_API_BASE_URL
      const {data} =  API.get(baseUrl + '/1.0/user')
    }
  },
  mounted() {
   this.loadData()
  }
}
</script>

<template>

</template>

<style scoped lang="scss">

</style>