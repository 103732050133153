export const userStore = {
    state: {
        login: false,
        data: false
    },
    getters: {
        getLogin(state){
            return state.login
        },
        getData(state){
            return state.data
        }
    },
    mutations: {
        setLogin(state, status){
           state.login = status
        },
        setData(state, data){
            state.data = data
        },
        changeData(state, data){
            state.data[data.key] = data.value
        }
    },
    actions: {
        setLogin(context, status){
            context.commit('setLogin', status)
        },
        setData(context, data){
            context.commit('setData', data)
        },
        changeData(context, data){
            context.commit('changeData', data)
        },
        logout(context){
            context.commit('setLogin', false)
            context.commit('setData', false)
            localStorage.removeItem('token')
        }
    },
    namespaced: true
}