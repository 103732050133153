import axios from "axios";
import router from "../router/index"

const api = axios.create()
const baseUrl = process.env.VUE_APP_API_BASE_URL
//start request
api.interceptors.request.use(config => {
    if(localStorage.token) {
        console.log(localStorage.token)
        config.headers.authorization = `Bearer ${localStorage.token}`
    }

    //Надо возвращать конфиг после его модификации
    return config
},error => {
    //Этот блок кода срабатывает только тогда, когда ошибка отправки запроса с фронта
    console.log(error)
})
//end request

//start response
api.interceptors.response.use(config => {
    if(localStorage.token) {
        config.headers.authorization = `Bearer ${localStorage.token}`
    }

    return config
}, error => {
    //Этот блок кода срабатывает когда прилетает ошибка с бэка

    if(error.response.data.message === 'Token has expired') {
        axios.post(baseUrl + '/1.0/auth/refresh-token').then(response => {
            localStorage.token = response.data.token

            //Делаем повторный запрос на получение данных с новым токеном
            //чтобы вручную не обновлять страницу
            error.config.headers.authorization = `Bearer ${response.data.token}`

            return api.request(error.config)
        })
    }

    // if(error.response.status === 401) {
    //     router.push('/login')
    // }
})
//end response

export default api
