<template>
  <div v-if="discountProducts.length" class="product-list-wrapper">
    <h3>Товары со скидкой</h3>
    <product-card-grid v-for="product in discountProducts" :product=product></product-card-grid>
  </div>
  <div v-else class="product-list-wrapper">
    <h3>В этой категории нет товаров</h3>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import router from "@/router";
import ProductCardGrid from "@/components/ProductCardGrid";
import axios from "axios";

export default {
  name: 'DiscountCategoryView',
  components: {
    ProductCardGrid
  },
  data(){
    return {}
  },
  computed:{
    ...mapGetters('app', [
        'discountProducts'
    ])
  }
}
</script>
<style lang="scss" scoped>
  .product-list-wrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 1.2%;
    padding: 15px;

    >h3{
      width: 100%;
      text-align: left;
    }

    >div {
      width: 19%;
      margin-bottom: 15px;
    }
  }
</style>
