import { createStore } from 'vuex'
import {cartStore} from "@/store/cartStore";
import {appStore} from "@/store/appStore";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
import {userStore} from "@/store/userStore";
const ls = new SecureLS({isCompression: false});
const state = createPersistedState({
  paths: ['cart', 'user'],
  storage: {
    getItem: (key) => ls.get(key),
    setItem: (key, value) => ls.set(key, value),
    removeItem: (key) => ls.remove(key),
  }
})
// localStorage.clear();
export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    cart: cartStore,
    app: appStore,
    user: userStore
  },
  plugins: [
    state
  ],
})
