<template>
  <div class="banner-wrapper">
    <div class="md:hidden" >
      <swiper v-if="show">
        <swiper-slide v-for="(slide, index) in banners" >
          <img class="w-full" :src="slide.picture_mobile"/>
        </swiper-slide>
      </swiper>
    </div>
    <div class="hidden md:block">
      <swiper v-if="show">
        <swiper-slide v-for="(slide, index) in banners" >
          <img class="w-full" :src="slide.picture" alt=""/>
        </swiper-slide>
      </swiper>
    </div>
    <div @click="switchBanner" class="switcher" :class="{rotate: !show}">
      <span>
        {{switcherText}}
        <font-awesome-icon v-if="show" :icon="['fas', 'angle-up']"></font-awesome-icon>
        <font-awesome-icon v-else :icon="['fas', 'angle-down']"></font-awesome-icon>
      </span>
    </div>
  </div>
</template>

<script>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {mapState} from "vuex";
import { Swiper, SwiperSlide } from 'swiper/vue';

export default {
  name: 'BannerCarousel',
  components: {
    FontAwesomeIcon,
    Swiper,
    SwiperSlide,
  },
  data(){
    return {
      show: true,
      switcherText: 'свернуть'
    }
  },
  computed: {
    ...mapState('app', [
        'banners'
    ])
  },
  methods: {
    switchBanner(){
      if(this.show === true){
        this.show = false
        this.switcherText = 'банер'
      }else{
        this.show = true
        this.switcherText = 'свернуть'
      }
    }
  }
}
</script>

<style scoped lang="scss">
.banner-wrapper{
  position: relative;

  .switcher{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    position: absolute;
    right: 0;
    bottom: -29px;
    cursor: pointer;
    background: url("@/assets/switch_carousel_bg.png");
    border: 1px solid #767373;
    border-top: none;
    border-radius: 0 0 5px 5px;
    background-size: cover;
    background-position-y: -3px;
    padding: 3px 0;
    z-index: 1;
  }

  .rotate{
    bottom: -31px;
  }
}
</style>
