<template>
  <div class="color_carousel_grid_wrapper" @click.prevent>
    <h3>{{ title }}</h3>
    <div class="pl-6 pr-6 flex relative w-full">
      <div class="swiper-button-prev" :class="prev"></div>
      <swiper class="w-full" :slides-per-view="4" space-between="5" :nested="true" :navigation="{nextEl: '.' + next, prevEl: '.' + prev}" :modules="modules">
        <swiper-slide class="p-0.5" v-for="color in sortableColors" :key=color.id>
          <div class="flex justify-center">
            <img class="h-6 w-full object-cover max-w-[100px]" :class="{activeColor: color.is_default}" @click="chooseColor" :src="color.pictureColor" :alt="color.name" :data-color-id="color.id">
          </div>
        </swiper-slide>
      </swiper>
      <div class="swiper-button-next" :class="next"></div>
    </div>
  </div>
</template>

<script>
import ProductCardGrid from "@/components/ProductCardGrid";
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation } from 'swiper/modules';
import 'swiper/css/navigation';
import {numeric} from "@vuelidate/validators";

export default {
  name: 'ColorCarouselGrid',
  components: {
    ProductCardGrid,
    Swiper,
    SwiperSlide
  },
  data(){
    return {
      modules: [Navigation]
    }
  },
  props: {
    title: String,
    colors: Object,
    countSlide: Number,
    productId: Number
  },
  computed: {
    sortableColors() {
      let colors = [...this.colors]
      colors.sort(function (a, b) {
        if (a.is_default === true) return -1
        if (b.is_default === true) return -1
        return a.is_default === b.is_default
      })
      return colors
    },
    next(){
      return 'next-' + this.productId
    },
    prev(){
      return 'prev-' + this.productId
    },
  },
  methods: {
    chooseColor(event) {
      let el = event.target;
      let colorId = el.getAttribute('data-color-id')
      let colors = document.getElementsByClassName('activeColor')
      Array.from(colors).forEach(function (color) {
        return color.classList.remove('activeColor')
      })
      let sizes = document.getElementsByClassName('activeSize')
      Array.from(sizes).forEach(function (size) {
        return size.classList.remove('activeSize')
      })
      el.classList.add('activeColor')
      this.$emit('chooseColor', colorId)
    }
  },
  // mounted() {
  //   this.colors.sort(function (a, b){
  //     if (a.is_default === true) return -1
  //     if (b.is_default === true) return -1
  //     return a.is_default === b.is_default
  //   })
  // }
}
</script>

<style scoped lang="scss">
.color_carousel_grid_wrapper {
  margin: 5px 0;
  .activeColor {
    box-shadow: 0 0 3px 2px rgba(219, 160, 74, 1);
    border: none;
  }
}
</style>
<style lang="scss">
.color_carousel_grid_wrapper{
  > .swiper{
    padding: 0 20px;

    .swiper-button-prev,
    .swiper-button-next{
      &:after{
        font-size: 12px !important;
      }
      .swiper-button-next{

      }
      .swiper-button-prev{

      }
    }
  }
}
</style>
