<template>
  <div class="product_carousel_wrapper">
    <h3>{{title}}</h3>
      <swiper :class="class" class="w-full" :navigation="true" :slides-per-view="1"  :space-between="30" :modules="modules" :free-mode="true" :breakpoints="{1600: {slidesPerView: 5}, 1200:{slidesPerView: 4}, 992:{slidesPerView: 3}, 768:{slidesPerView: 2}}">
        <swiper-slide v-for="product in products">
          <product-card-grid :product=product></product-card-grid>
        </swiper-slide>
      </swiper>
  </div>
</template>
<script>
import ProductCardGrid from "@/components/ProductCardGrid";
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation } from 'swiper/modules';
import 'swiper/css/navigation';

export default {
  name: 'ProductCarousel',
  components: {
    ProductCardGrid,
    Swiper,
    SwiperSlide,
    Navigation
  },
  data(){
    return {
      modules: [Navigation]
    }
  },
  props: {
    title: String,
    products: Object,
    class: String
  }
}
</script>

<style scoped lang="scss">
.product_carousel_wrapper{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  h3{
    width: 100%;
    text-transform: uppercase;
    font-weight: 600;
    text-align: left;
    padding: 10px 0 10px 20px;
  }
}

</style>
<style lang="scss">
.product_carousel_wrapper{
  > .swiper{
    padding: 0 30px;
  }
}
.swiper-button-prev,
.swiper-button-next{
  padding: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer;
  background: #C7C7C7;
  margin-top: calc(0px - (var(--swiper-navigation-size) / 4));

  &:after{
    font-size: 11px;
    font-weight: bold;
    color: black;
  }
}
.swiper-button-next{
  right: 2px;
}
.swiper-button-prev{
  left: 2px;
}
</style>
