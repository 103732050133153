export const cartStore = {
    state: {
        productsArray: []
    },
    getters: {
        totalSum(state) {
            if (state.productsArray.length === 0) {
                return 'Пусто'
            }
            let sum = 0;
            state.productsArray.forEach(function (item){
                sum += item.price * item.count
            })

            return new Intl.NumberFormat().format(sum) + ' руб.'
        },
        totalCount(state) {
            if (state.productsArray.length === 0) {
                return 0
            }
            return state.productsArray.reduce(function (previousValue, currentValue) {
                return Number(previousValue) + Number(currentValue.count)
            }, '')
        },
        totalWeight(state){
            if (state.productsArray.length === 0) {
                return 0
            }
            return state.productsArray.reduce(function (previousValue, currentValue) {
                return Number(previousValue) + Number(currentValue.weight)
            }, '')
        },
        isEmpty(state) {
            return state.productsArray.length === 0
        },
        products(state) {
            return state.productsArray
        }
    },
    mutations: {
        addProduct(state, product) {
            let existsProduct = state.productsArray.find(item => item.id === product.id && item.colorId === product.colorId && item.sizeId === product.sizeId)
            if(existsProduct !== undefined){
                existsProduct.count += product.count
                return
            }
            state.productsArray.push(product)
        },
        deleteProduct(state, index) {
            state.productsArray.splice(index, 1)
        }
    },
    actions: {
        addProduct(context, product) {
            context.commit('addProduct', product)
        },
        deleteProduct(context, index) {
            context.commit('deleteProduct', index)
        },
        incrementProduct(context, index){
            context.state.productsArray[index].count++
        },
        decrementProduct(context, index){
            let product = context.state.productsArray[index]
            if(product.count > 1){
                context.state.productsArray[index].count--
            }else{
                context.commit('deleteProduct', index)
            }
        }
    },
    namespaced: true
}