<template>
  <div class="size-grid-wrapper">
    <table @click.prevent.capture="chooseSize">
      <tr>
        <th><img class="color-image" :src="colorImage" alt=""></th>
        <th v-for="size in horizontalSizes">{{size}}</th>
      </tr>
      <tr v-for="verticalSize in verticalSizes">
        <th>{{verticalSize}}</th>
        <td v-for="horizontalSize in horizontalSizes" :data-size-index="horizontalSize+verticalSize">{{getCountForSize(horizontalSize+verticalSize)}}</td>
      </tr>
    </table>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'SizeGrid',
  props:{
    colorId: {
      type: Number,
      required: true
    },
    dimensionalGridId: {
      required: true
    },
    numberOfSizes: Object,
    colorImage: String
  },
  computed:{
    ...mapGetters('app', [
      'dimensionalGrids'
    ]),
    sizes(){
      if(this.dimensionalGrids.length !== 0 && this.dimensionalGridId){
        return this.dimensionalGrids[this.dimensionalGridId]['sizes']
      }

      return [];
    },
    verticalSizes(){
      if(this.sizes){
        return [...new Set(Object.values(this.sizes).map(a => a.first_parameter))]
      }
      return []
    },
    horizontalSizes(){
      if(this.sizes){
        return [...new Set(Object.values(this.sizes).map(a => a.second_parameter))]
      }
      return []
    }
  },
  methods: {
    getCountForSize(sizeIndex){
      if(this.sizes.hasOwnProperty(sizeIndex)){
        let colorId = String(this.colorId)
        let sizeId = String(this.sizes[sizeIndex].id)
        let property = colorId+sizeId
        if(this.numberOfSizes.hasOwnProperty(property)){
          return this.numberOfSizes[property].count;
        }
      }
      return '-';
    },
    chooseSize(event){
      let el = event.target;
      let sizeIndex = el.getAttribute('data-size-index')
      if(sizeIndex === null){
        return
      }
      if(
          this.sizes.hasOwnProperty(sizeIndex) &&
          this.getCountForSize(sizeIndex) !== '-'
      ){
        let sizes = document.getElementsByClassName('activeSize')
        Array.from(sizes).forEach(function (size){
          return size.classList.remove('activeSize')
        })
        el.classList.add('activeSize')
        this.$emit('chooseSize', this.sizes[sizeIndex].id, sizeIndex)
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .size-grid-wrapper{
    width: 100%;

    table{
      width: 100%;
      border: 1px;
      border-collapse: collapse;
      text-align: center;
      font-size: 14px;
      background: #F3F3F3;

      tr{
        &:first-child{
          th{
            &:first-child{
              width: 50px;
              height: 23px;
            }
          }
        }
      }

      th{
        background-color: #D8D8D8;
        border: 1px solid white;
        font-weight: 400;
        text-align: center;
      }

      td{
        background-color: #F3F3F3;
        border: 1px solid white;
        height: 20px;
        font-weight: 600;
        cursor: pointer;
        user-select: none;
      }

      .color-image{
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .activeSize{
        background-color: #D8D8D8 !important;
      }
    }
  }
</style>
