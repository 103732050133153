<template>
  <header class="pr-4">
      <div class="hidden md:flex w-full">
        <div class="filter-button-wrapper hidden md:flex">
          <font-awesome-icon :icon="['fas', 'sliders']"/>
        </div>
        <div class="logo-wrapper" @mouseover="hideAdditionalMenu">
          <router-link to="/"><img src="../assets/logo.png" alt="Лого"></router-link>
        </div>
        <div class="center-wrapper">
          <div class="top" @mouseover="hideAdditionalMenu">
            <div class="search-wrapper relative">
              <div class="flex w-1/2 mr-3 sm:w-4/5 sm:h-[25px] lg:h-3/5 lg:w-1/3 py-0.5">
                <span><font-awesome-icon :icon="['fas', 'magnifying-glass']"/></span>
                <input v-model="searchProductString" @input="searchProduct" type="text">
              </div>
              <div class="flex items-center w-1/2 lg:w-1/4 sm:w-4/5 sm:h-[25px] lg:h-3/5 py-0.5">
                <span><font-awesome-icon :icon="['fas', 'magnifying-glass-location']"/></span>
                <input type="text">
                <font-awesome-icon :icon="['fas', 'info']"/>
              </div>
              <div v-if="searchProductResult.length"
                   class="absolute border -mb-6 top-12 left-0 right-0 md:w-[100%] w-[43%] z-10 bg-white p-3">
                <router-link v-for="product in searchProductResult" :to="'/catalog/clothes/' + product.alias">
                  <div class="flex justify-between items-center pr-4">
                    <img class="h-16" :src="product.productColors[0].pictureProduct" alt="">
                    <p v-html="product.name"></p>
                    <p>{{ new Intl.NumberFormat().format(product.price)}} ₽</p>
                  </div>
                </router-link>
              </div>
            </div>
            <div class="auth-wrapper flex mr-1 items-center">
              <div class="flex items-center h-full" v-if="login">
                <font-awesome-icon class="md:hidden" :icon="['fas', 'house-user']" />
                <button class="hidden user-btn mr-2 md:inline-block">Личный кабинет</button>
                <font-awesome-icon @click="logout" :icon="['fas', 'arrow-right-from-bracket']" class="cursor-pointer"/>
              </div>
              <div class="flex items-center h-full" v-else>
                <font-awesome-icon @click="setShowAuthForm(true)" :icon="['far', 'user']" class="cursor-pointer"/>
              </div>
            </div>
            <div class="cart-wrapper" @click="$router.push('/cart')">
              <font-awesome-icon :icon="['fas', 'cart-shopping']"/>
              <i>{{ totalCount }}</i>
              <p>: {{ totalSum }}</p>
            </div>
          </div>
          <div class="bottom hidden md:flex">
            <nav>
              <router-link v-for="category in categories" @mouseover="showAdditionalMenu(category.alias)"
                           :to="'/catalog/' + category.alias">{{ category.name }}
              </router-link>
              <router-link :to="'/discount'" @mouseover.self="hideAdditionalMenu">дисконт</router-link>
              <router-link :to="'/contacts'" @mouseover.self="hideAdditionalMenu">контакты</router-link>
            </nav>
            <div class="additional-menu-wrapper" v-if="visibleAdditionalMenu" @mouseover.self="hideAdditionalMenu">
              <div class="additional-menu-wrapper__content">
                <div v-if="childCategories" v-for="category in childCategories">
                  <router-link :to="'/catalog/' + category.alias">{{ category.name }}</router-link>
                  <div v-if="category.childCategories">
                    <router-link class="block" v-for="childCategory in category.childCategories" :to="'/catalog/' + childCategory.alias">
                      {{ childCategory.name }}
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex md:hidden w-full items-center gap-2 justify-between relative">
        <div class="border min-w-11 h-full"></div>
        <router-link to="/"><img class="h-9 pl-1" src="../assets/logo.png" alt="Лого"></router-link>
        <div>
          <div v-if="login" class="flex gap-2">
            <font-awesome-icon :icon="['fas', 'house-user']" class="svg cursor-pointer h-[22px]"/>
            <font-awesome-icon @click="logout" :icon="['fas', 'arrow-right-from-bracket']" class="cursor-pointer svg h-[22px]"/>
          </div>
          <div v-else>
            <font-awesome-icon @click="setShowAuthForm(true)" :icon="['far', 'user']" class="cursor-pointer svg h-[22px] pt-1"/>
          </div>
        </div>
        <div class="flex gap-2">
          <font-awesome-icon :icon="['fas', 'magnifying-glass']" class="svg cursor-pointer h-[22px]" @click="toggleProductSearch"/>
          <font-awesome-icon :icon="['fas', 'magnifying-glass-location']" class="svg cursor-pointer h-[22px]" @click="toggleOrderSearch"/>
        </div>
        <div class="relative">
          <font-awesome-icon @click="$router.push('/cart')" :icon="['fas', 'cart-shopping']" class="svg cursor-pointer h-[22px] pt-1"/>
          <i class="absolute flex bg-red-700 w-4 h-4 left-1.5 top-[-4px] items-center pl-1 text-white rounded-full">{{ totalCount }}</i>
        </div>
        <div v-if="visibleProductSearch" class="search-field absolute w-full bottom-[-40px] z-10 px-4 py-2">
          <input v-model="searchProductString" @input="searchProduct" type="text" class="h-6 w-full" placeholder="Поиск товара">
        </div>
        <div v-if="visibleOrderSearch" class="search-field absolute w-full bottom-[-40px] z-10 px-4 py-2">
          <input type="text" class="h-6 w-full" placeholder="Поиск заказа">
        </div>
      </div>
  </header>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";

export default {
  name: 'MainHeader',
  data() {
    return {
      visibleAdditionalMenu: false,
      categoryAlias: null,
      timeout: false,
      searchProductString: '',
      searchProductResult: [],
      visibleProductSearch: false,
      visibleOrderSearch: false
    }
  },
  computed: {
    ...mapGetters('cart', [
      'totalSum',
      'totalCount'
    ]),
    ...mapGetters('app', [
      'categories',
      'products'
    ]),
    ...mapState('user', [
      'login',
      'data'
    ]),
    childCategories() {
      if (this.categories !== [] && this.categoryAlias !== null) {
        return this.categories.find(item => item.alias === this.categoryAlias).childCategories
      }
      return false;
    }
  },
  methods: {
    ...mapActions('app', [
      'setShowAuthForm'
    ]),
    ...mapActions('user', [
      'setLogin',
      'setData'
    ]),
    showAdditionalMenu(categoryIndex) {
      this.timeout = setTimeout(() => {
        this.visibleAdditionalMenu = true
        this.categoryAlias = categoryIndex
      }, 200)

    },
    hideAdditionalMenu() {
      clearTimeout(this.timeout)
      this.visibleAdditionalMenu = false
      this.categoryAlias = null
    },
    logout() {
      this.setLogin(false)
      this.setData(false)
      localStorage.removeItem('token')
    },
    searchProduct() {
      if (this.searchProductString.length > 2) {
        this.searchProductResult = this.products.filter(product => product.productKeyword.toLowerCase().includes(this.searchProductString.toLowerCase()) || product.name.toLowerCase().includes(this.searchProductString.toLowerCase()))
      } else {
        this.searchProductResult = []
      }
    },
    clearProductSearch() {
      this.searchProductResult = []
      this.searchProductString = ''
    },
    toggleProductSearch(){
      this.visibleProductSearch = !this.visibleProductSearch
    },
    toggleOrderSearch(){
      this.visibleOrderSearch = !this.visibleOrderSearch
    }
  },
  created() {
    document.addEventListener('click', () => this.clearProductSearch());
  }
}
</script>

<style scoped lang="scss">
header {
  width: 100%;
  height: 9vh;
  background: url("../assets/header_bg.jpg") no-repeat;
  background-size: cover;
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    padding: 0;
    height: 5vh;
  }

  .svg{
    color: #e2e2e2;
  }

  .search-field{
    background-color: #514F50;
  }

  .filter-button-wrapper,
  .favourites-button-wrapper {
    width: 75px;
    height: 100%;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      background-color: #656565;

      svg {
        color: #fff;
      }
    }
  }

  .filter-button-wrapper {
    svg {
      height: 40%;
      color: #e2e2e2;
    }
  }

  .logo-wrapper {
    display: inline-flex;
    height: 100%;
    width: 10%;
    justify-content: center;
    min-width: 230px;
    @media (max-width: 1200px){
      width: 7%;
      min-width: 180px;
    }
    @media (max-width: 1200px){
      width: 7%;
      min-width: 120px;
    }
    a {
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: left;

      img {
        height: 80%;
        @media (max-width: 1200px){
          height: 60%;
        }
        @media (max-width: 1200px){
          height: auto;
          padding: 5px;
        }
      }
    }
  }

  .center-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;

    .top {
      height: 70%;
      display: flex;
      justify-content: space-between;

      .search-wrapper {
        display: flex;
        align-items: center;
        flex-grow: 1;
        @media (max-width: 992px) {
          flex-direction: column;
          justify-content: center;
          align-items: start;
        }
        @media (max-width: 768px) {
          display: none;
        }

        > div {
          > svg {
            color: #e2e2e2;
            border: 1px solid #e2e2e2;
            padding: 5px 10px;
            border-radius: 5px;
            margin-left: 10px;
            @media (max-width: 992px) {
              position: absolute;
              right: 0;
              top: 20px;
            }
          }

          span {
            border-radius: 4px 0 0 4px;
            background-color: #8C8C8C;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;

            svg {
              height: 60%;
              color: #e2e2e2;
            }
          }

          input {
            width: 100%;
            height: 100%;
            border-radius: 0 4px 4px 0;
            border: none;
            color: #656565;
            background-color: #e2e2e2;

            &:focus-visible {
              outline: none;
            }
          }
        }
      }

      .auth-wrapper {
        .user-btn {
          background: url("@/assets/auth_bg.png") no-repeat;
          border-radius: 5px;
          color: #000;
          margin-right: 10px;
          padding: 5px 10px;
          -webkit-background-size: cover;
          background-size: cover;
          @media (max-width: 1200px) {
            min-width: 140px;
            margin-left: 8px;
          }
          &:hover {
            color: #AF6500;
          }
        }

        svg {
          color: #e2e2e2;
          height: 50%;
          margin-right: 10px;
          @media (max-width: 1200px) {
            height: 35%;
          }
        }
      }

      .cart-wrapper {
        display: flex;
        align-items: center;
        position: relative;

        svg {
          color: #e2e2e2;
          height: 50%;
          margin-right: 10px;
          cursor: pointer;
          @media (max-width: 1200px) {
            height: 35%;
          }
        }

        p {
          color: #E39934;
          @media (max-width: 1200px) {
            font-size: 12px;
            min-width: 70px;
          }
        }

        i {
          position: absolute;
          background-color: #FE0000;
          width: 20px;
          height: 20px;
          font-size: 15px;
          border-radius: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;
          color: white;
          font-style: normal;
          top: 7px;
          left: 13px;
          @media (max-width: 1200px) {
            width: 17px;
            height: 17px;
            left: 9px;
            top: 9px;
          }
        }
      }
    }

    .bottom {
      height: 30%;
      position: relative;

      nav {
        display: flex;
        justify-content: space-between;
        width: 100%;

        a {
          text-transform: uppercase;
          text-decoration: none;
          font-size: 14px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;

          &:hover {
            background-color: #e2e2e2;
            color: #434343;
          }

          //&.router-link-exact-active {
          //  color: #42b983;
          //}
        }
      }

      .additional-menu-wrapper {
        position: absolute;
        width: 100%;
        height: 48vh;
        z-index: 2;
        top: 28px;
        left: -20px;
        padding: 0 20px;

        .additional-menu-wrapper__content {
          width: 60.5%;
          height: 45vh;
          box-shadow: 0 2px 10px 2px #888888;
          background-color: #fff;
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          padding: 20px;

          > div {
            height: 50%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            > a {
              font-size: 24px;
              font-weight: 600;
              color: #C98552;
              text-decoration: none;
              margin-bottom: 10px;
            }

            > div {
              > a {
                font-size: 18px;
                color: #222222;
                text-decoration: none;
              }
            }
          }
        }
      }
    }
  }

  .favourites-button-wrapper {
    svg {
      height: 80%;
      color: #e2e2e2;

      &:last-child {
        margin-top: 15px;
        height: 38%;
      }
    }
  }
}
</style>
