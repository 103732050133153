<template>
  <div class="product-list-wrapper" v-if="currentCategory && currentCategory.childCategories" v-for="category in currentCategory.childCategories">
    <h3>{{category.name}}</h3>
    <product-card-grid v-if="getProductsForCategory(category.alias)" v-for="product in getProductsForCategory(category.alias)" :product=product></product-card-grid>
    <div v-if="category.childCategories" v-for="childCategory in category.childCategories">
        <product-card-grid v-if="getProductsForCategory(childCategory.alias)" v-for="product in getProductsForCategory(childCategory.alias)" :product=product></product-card-grid>
    </div>
  </div>
  <div class="product-list-wrapper" v-if="currentCategory && !currentCategory.childCategories.length">
    <product-card-grid v-if="getProductsForCategory(currentCategory.alias).length" v-for="product in getProductsForCategory(currentCategory.alias)" :product=product></product-card-grid>
    <h3 v-else>В этой категории нет товаров</h3>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import router from "@/router";
import ProductCardGrid from "@/components/ProductCardGrid";
import axios from "axios";

export default {
  name: 'CategoryView',
  components: {
    ProductCardGrid
  },
  data(){
    return {}
  },
  computed:{
    ...mapState('app', [
      'currentCategoryAlias'
    ]),
    ...mapGetters('app', [
        'currentCategory',
        'products'
    ])
  },
  methods:{
    ...mapActions('app', [
        'setCurrentCategoryAlias'
    ]),
    getProductsForCategory(categoryAlias){
      return this.products.filter(item => item.categories.find(item => item.alias === categoryAlias))
    },
    // saveOrder(){
    //   let baseUrl = process.env.VUE_APP_API_BASE_URL
    //   axios
    //       .post(baseUrl + '/1.0/order')
    //       .then((response) => {
    //         this.setNewProducts(response.data)
    //       })
    // }
  },
  mounted() {
    this.setCurrentCategoryAlias(router.currentRoute.value.params.category_alias)
  }
}
</script>
<style lang="scss" scoped>
  .product-list-wrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 1.2%;
    padding: 15px;

    >h3{
      width: 100%;
      text-align: left;
    }

    >div {
      width: 19%;
      margin-bottom: 15px;

      @media (max-width: 1600px) {
        width: 24%;
      }

      @media (max-width: 1200px) {
        width: 32%;
      }

      @media (max-width: 768px) {
        width: 49%;
      }

      @media (max-width: 640px) {
        width: 98%;
      }
    }
  }
</style>
