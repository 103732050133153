<script>
import {mapActions, mapState} from "vuex";
import axios from "axios";
import API from "@/utils/api";

export default {
  name: "AuthForm",
  data(){
    return {
      login: '',
      password: '',
      passwordType: 'password',
      passwordIcon: 'eye'
    }
  },
  computed: {
    showAuth: {
      get(){
        return this.$store.state.app.showAuthForm
      },
      set(value){
        this.setShowAuthForm(value)
      }
    }
  },
  methods:{
    ...mapActions('app', [
        'setShowAuthForm',
        'setShowSignUpForm'
    ]),
    ...mapActions('user', [
        'setLogin',
        'setData'
    ]),
    enter(){
      let baseUrl = process.env.VUE_APP_API_BASE_URL
      try {
        axios.post(baseUrl + '/1.0/auth/token', {login: this.login, password: this.password})
            .then(response => {
              if(response.status === 200){
                this.login = ''
                this.password = ''
                localStorage.token = response.data.token
                this.setShowAuthForm(false)
                this.setLogin(true)
                this.getUserData()
              }
            })
      }catch (error){
        console.log(error)
      }


    },
    getUserData(){
      let baseUrl = process.env.VUE_APP_API_BASE_URL
      API.get(baseUrl + '/1.0/user')
          .then(response => {
            this.setData(response.data)
          })
    },
    changePasswordType(){
      if(this.passwordType === 'password'){
        this.passwordType = 'text'
        this.passwordIcon = 'eye-slash'
      }else{
        this.passwordType = 'password'
        this.passwordIcon = 'eye'
      }
    }
  }
}
</script>

<template>
  <Modal wrapperClass="modal-auth" v-model="showAuth" title="Вход в личный кабинет" :modal-class="'w-1/5'">
    <label class="block">
      <span class="text-sm text-gray-500">Логин</span>
      <input v-model="login" class="mt-1 block w-full h-11 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" type="text">
    </label>
    <label class="relative block">
      <span class="text-sm text-gray-500">Пароль</span>
      <input v-model="password" class="mt-1 block w-full h-11 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" :type="passwordType">
      <font-awesome-icon @click.prevent="changePasswordType" class="absolute right-2 top-10 cursor-pointer" :icon="['fas', passwordIcon]" />
    </label>
    <button @click="enter" class="login-button" type="button">Войти</button>
    <div class="flex justify-between">
      <p class="underline cursor-pointer">Восстановить пароль</p>
      <p @click="setShowSignUpForm(true)" class="underline cursor-pointer">Зарегистрироваться</p>
    </div>
  </Modal>
</template>

<style scoped lang="scss">
.login-button{
  margin-top: 10px;
  width: 100%;
  display: block;
  margin-bottom: 4px;
  height: auto;
  padding-top: calc(.75em - 1px);
  padding-bottom: calc(.75em - 1px);
  border: none !important;
  box-shadow: 0 3px 5px rgba(0, 0, 0, .5);
  border-radius: 5px;
  color: white;
  box-sizing: border-box;
  text-decoration: none !important;
  background: rgb(75, 214, 111);
  background: linear-gradient(0deg, rgba(75, 214, 111, 1) 0%, rgba(26, 166, 59, 1) 100%);
  transition: .3s ease-out;
}
</style>